import { concat, filter, fromPromise, map, pipe } from "wonka";
import { Override } from "../types";
import { strToDate } from "../utils/dates";
import { deserialize } from "../utils/wonka";
import {
  DailyHabit as HabitDto,
  Event as EventDto,
  Moment as MomentDto,
  SubscriptionType,
  Task as TaskDto,
} from "./client";
import { dtoToEvent, Event } from "./Events";
import { dtoToHabit, Habit } from "./Habits";
import { isHabit, isTask, TaskOrHabit } from "./Planner";
import { dtoToTask, Task } from "./Tasks";
import { TransformDomain } from "./types";

export type Moment = Override<
  MomentDto,
  {
    event?: Event;
    task?: Task;
    habit?: Habit;
    additionalEvents?: Event[];
    allEvents: Event[];
    now?: Date;
  }
>;

export const dtoToMoment = (dto: MomentDto): Moment => {
  const moment: Moment = {
    now: strToDate(dto.now),
    allEvents: [dto.event, ...dto.additionalEvents].map(dtoToEvent),
  };

  if (!!dto.event) {
    moment.event = dtoToEvent(dto.event);
  }

  if (!!dto.additionalEvents && !!dto.additionalEvents.length) {
    moment.additionalEvents = dto.additionalEvents.map((e: EventDto) => dtoToEvent(e));
  }

  const taskOrHabit: TaskOrHabit = dto.assignment;

  if (!!dto.assignment) {
    if (isTask(taskOrHabit)) {
      moment.task = dtoToTask(taskOrHabit as TaskDto);
    } else if (isHabit(taskOrHabit)) {
      moment.habit = dtoToHabit(taskOrHabit as HabitDto);
    } else {
      console.warn("Unidentified moment assignment", dto);
    }
  }

  return moment;
};

const MomentSubscription = {
  subscriptionType: SubscriptionType.Moment,
};

export class MomentDomain extends TransformDomain<Moment, MomentDto> {
  resource = "Moment";
  cacheKey = "moments";
  pk = ["assignment.id"]; // TODO: (SS) Not used but as moment evolves the upsert might be needed.

  public deserialize = dtoToMoment;

  watch$$ = () =>
    pipe(
      this.ws.subscription$$(MomentSubscription),
      filter((envelope) => !!envelope.data),
      deserialize(this.deserialize)
    );

  list$$ = () =>
    pipe(
      fromPromise(this.list()),
      map((moment: Moment) => (!!moment ? [moment] : null))
    );

  listAndWatch$$ = () =>
    pipe(
      concat<Moment | Moment[] | null>([this.list$$(), this.watch$$()]),
      // only one item for moment
      map((moments?: Moment[]) => (!!moments?.length && !!Object.keys(moments[0]).length ? moments[0] : null))
    );

  list = this.manageErrors(this.deserializeResponse(this.api.moment.get2));
}
